<template>
    <div class="home-main">
        <WebNavbar />
        <main class="main">
            <div class="container-fluid">
                <div class="row pt-3" :class="{'scroll-horizontal': platform == 'mobile'}">
                    <div class="col">
                        <template v-if="platform == 'desktop'">
                            <div class="dropdown">
                                <button class="btn btn-sm btn-block dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{ current_day }}
                                    <span class="badge" v-if="total && !loading">
                                        {{ total }}
                                    </span>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" ref="dropdown-menu">
                                    <a class="dropdown-item" href="javascript:;" v-for="(item, index) in buttons" @click="date = item.date" :class="{'active disabled': date == item.date}" :key="index">
                                        {{ item.label }}
                                    </a>
                                </div>
                            </div><!-- /dropdown-->
                        </template>
                        <template v-else>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">
                                        <i class="fas fa-filter"></i>
                                    </span>
                                </div>
                                <select class="form-control form-sm form-bg" @change="changeDate">
                                    <option v-for="(item, index) in buttons" :selected="date == item.date" :value="item.date" :key="index">
                                        {{ item.label }}
                                        <template v-if="total && !loading && date == item.date">&nbsp;({{ total }})</template>
                                    </option>
                                </select>
                            </div>
                        </template>
                    </div>
                    <div class="col">
                        <div class="btn-group w-100" :class="{'disabled': total == 0}">
                            <button class="btn btn-sm text-uppercase" @click="selectEventsPrint">
                                <template v-if="!selectedAll">
                                    <i class="fas fa-check-square mr-2"></i>Selecionar tudo
                                </template>
                                <template v-else>
                                    <i class="fas fa-minus-square mr-2"></i>Desmarcar tudo
                                </template>
                            </button>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="btn-group w-100" :class="{'disabled': selectedPrintList.length == 0}">
                            <button class="btn btn-sm text-uppercase" @click="printTable">
                                <i class="fas fa-print mr-2"></i>Imprimir
                            </button>
                        </div>
                    </div>
                </div><!-- /row -->
                <div class="mt-3 h-100vh">
                    <template v-if="loading">
                        <div class="d-flex justify-content-center align-items-center h-100">
                            <div v-html="lds_ring"></div>
                        </div>
                    </template>
                    <template v-else>
                        <template v-if="message">
                            <div class="d-flex flex-column justify-content-center align-items-center text-center h-100">
                                <div class="display-5">
                                    <i class="fas fa-exclamation-circle"></i>
                                </div>
                                <small>{{ message }}</small>
                            </div>
                        </template>
                        <template v-else>
                            <div class="collection-cluster">
                                <ul class="list-group ">
                                    <li class="list-group-item list-group-item-dark" v-for="(item, index) in leaguesOrganizes(events)" :key="index">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" :checked="selectedAll" @change="selectLeaguePrint($event)" :name="item.liga" :value="item.liga_id" :id="item.liga_id">
                                            <label class="form-check-label text-style" :for="item.liga_id">
                                                {{ item.liga }}
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </template>
                </div>
            </div>
        </main>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    </div>
</template>

<script>
import Vue from 'vue'
import {api} from '../../api'
import WebNavbar from './WebNavbar'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'v-slim-dialog/dist/v-slim-dialog.css'
import SlimDialog from 'v-slim-dialog'

Vue.use(SlimDialog)

const format = 'YYYY-MM-DD';
const now = moment().tz('America/Recife');
const today = moment(now).format(format);
const tomorrow = moment(now).add(1, 'days').format(format);
const after_tomorrow = moment(tomorrow).add(1, 'days').format(format);

export default {
    data() {
        return {
            total: 0,
            sport: 1,
            events: [],
            configs: {},
            buttons: [{
                label: 'Hoje',
                date: today
            },{
                label: 'Amanhã',
                date: tomorrow
            },{
                label: 'Depois de amanhã',
                date: after_tomorrow
            }],
            date: today,
            message: '',
            loading: false,
            isLogged: false,
            isLoading: false,
            selectedAll: false,
            current_day: 'Hoje',
            selectedPrintList: [],
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            site: window.location.protocol == 'https:' ? 'https://' + window.location.hostname : 'http://' + window.location.hostname
        }
    },
    components: {
        Loading,
        WebNavbar
    },
    methods: {
        selectEventsPrint() {
            if (this.selectedAll) {
                this.selectedAll = false;
                this.selectedPrintList = [];
            } else {
                this.selectedAll = true;
                this.selectedPrintList = this.events.map((item) => {
                    return item.liga_id;
                });
            }
        },
        printTable() {

            const events = [];

            if (this.selectedPrintList.length == 0) {
                this.showAlert(
                    'Atenção!', 
                    'Selecione pelo menos um campeonato!'
                );
            } else {

                this.events.forEach((item) => {
                    
                    if (this.selectedPrintList.includes(item.liga_id)) {

                        events.push({
                            liga: item.liga,
                            esporte: item.esporte,
                            time_casa: item.time_casa,
                            time_fora: item.time_fora,
                            horario: item.horario,
                            odds: {
                                casa: item.odds.casa,
                                empate: item.odds.empate,
                                visitante: item.odds.visitante,
                                total_de_gols_acima_2_5: item.odds.extra.total_de_gols.acima_2_5,
                                ambas_equipes_marcam_sim: item.odds.extra.ambas_equipes_marcam.sim
                            }
                        })
                    }
                });

                if (events.length == 0) {
                    this.showAlert(
                        'Atenção!', 
                        'Nenhum evento disponível para a impressão!'
                    );
                } else if (this.isCordova) {

                    this.selectedAll = false;
                    this.selectedPrintList = [];

                    window.location.href = 'betsnet://print-table?data=' + window.btoa(unescape(encodeURIComponent(JSON.stringify({
                        events: events,
                        site: this.site,
                        date: this.date,
                        configs: this.configs
                    }))));
                } else {

                    let html = ``;
                    let anteior_value = 0;

                    this.selectedAll = false;
                    this.selectedPrintList = [];

                    events.forEach((item) => {

                        if (anteior_value != item.liga) {
                            html += `
                                <tr>
                                    <td colspan="12">
                                        <div class="separator"></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="12" align="center" class="bg">
                                        <span>${item.liga}</span>
                                    </td>
                                </tr>
                            `;
                        }

                        html += `
                            <tr>
                                <td align="left" colspan="8">${item.time_casa}</td>
                                <td align="right" colspan="4">${this.formatHour(item.horario)}</td>
                            </tr>
                            <tr>
                                <td align="left" colspan="8">${item.time_fora}</td>
                                <td align="right" colspan="4">${this.findModality(item.esporte)}</td>
                            </tr>
                            <tr>
                                <td align="center" width="20%" class="bg no-border">1<div>${item.odds.casa}</div></td>
                                <td align="center" width="20%" class="bg no-border">X<div>${item.odds.empate}</div></td>
                                <td align="center" width="20%" class="bg no-border">2<div>${item.odds.visitante}</div></td>
                                <td align="center" width="20%" class="bg no-border">AMB.S<div>${item.odds.ambas_equipes_marcam_sim}</div></td>
                                <td align="center" width="20%" class="bg no-border">+2.5<div>${item.odds.total_de_gols_acima_2_5}</div></td>
                            </tr>
                        `;
                        anteior_value = item.liga
                    });

                    let template = `
                        <html>
                            <head>
                                <style>*{margin:0;padding:0}body,html,table{font-size:12px;font-family:"Andale Mono",AndaleMono,monospace;width:100%}.print-table{margin:1em .2em}.print-table .site{font-size:24px;font-weight:700;text-transform:uppercase;text-align:center;margin-bottom:.5em}.print-table .site img{filter: brightness(0%)}.print-table table{width:100%;border:none}.print-table table td{padding:2px}.print-table table tr td .separator{margin:.5em}.print-table table tr td.border{border:1px solid #000}.print-table table tr td.bg{color:#fff;background-color:#000;text-transform:uppercase}.print-table table tr td.no-border{border:none!important}.print-table table tr td.no-border div{background-color:#fff;color:#000}</style>
                            </head>
                            <body>
                                <div class="print-table">
                                    <div class="site">
                                        <img src="${this.logo}" border="0" width="200" alt="${this.configs.nome}">
                                    </div>
                                    <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                        <tbody>
                                            <tr>
                                                <td colspan="12" align="center" class="border">${this.formatDateDay(today)}</td>
                                            </tr>
                                            ${html}
                                            <tr>
                                                <td colspan="12">
                                                    <div class="separator">
                                                        <center>
                                                            <div>Mais jogos em:</div>
                                                            <div>${['www', window.location.host].join('.')}</div>
                                                        </center>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </body>
                        </html>
                    `;
                    
                    this.printData(template);
                }
            }
        },
        getEvents() {

            const self = this;
            const sport = self.sport == 0 ? 1 : self.sport;
            const url = self.isLogged ? `events/${self.date}/${sport}/by/${self.user_id}` : `events/${self.date}/${sport}`;

            self.message = '';
            self.loading = true;

            if (Object.keys(self.configs).length == 0) return self.loadConfigs();

            api.get(url).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.events = self.orderEvents(response.data.events);
                    break;
                    case 'nothing_found':
                        self.message = 'Nenhum evento disponível';
                    break;
                    default:
                        self.message = response.data.message;
                    break;
                }
            }).catch((error) => {
                try {
                    self.message = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.message = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        loadConfigs() {

            const self = this;

            self.isLoading = true;

            api.get('configs').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.configs = response.data.configs;
                        self.user_id = response.data.configs.user_id;
                        self.isLogged = response.data.configs.situacao;
                        self.current_credit = self.format_coin(response.data.configs.credito);
                        self.current_credit_simple = self.format_coin(response.data.configs.credito_simples);
                        self.getEvents();
                    break;
                    case 'jwt_expired':
                        self.message = 'Sua sessão expirou, faça o login novamente!';
                    break;
                    default:
                        self.message = 'Ocorreu um problema, recarregue a página!';
                    break;
                }
            }).catch((error) => {
                try {
                    self.message = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.message = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.isLoading = false;
            });
        },
        leaguesOrganizes(arr) {
            return arr.reduce((unique, o) => {
                if(!unique.some(obj => obj.liga_id === o.liga_id)) {
                    unique.push(o);
                }
                return unique;
            },[]).sort((a, b) => {
                return a.liga.localeCompare(b.liga)
            });
        },
        orderEvents(events) {
            return events.map((item) => {
                return {
                    id: item.id,
                    ao_vivo: item.ao_vivo,
                    esporte: item.esporte,
                    liga: item.liga,
                    liga_id: item.liga_id,
                    time_casa: item.time_casa,
                    time_fora: item.time_fora,
                    hora: item.hora,
                    horario: item.horario,
                    odds: item.odds,
                }
            }).sort((a, b) => {
                
                if (this.remove_accents(a.liga) < this.remove_accents(b.liga)) return -1;
                if (this.remove_accents(a.liga) > this.remove_accents(b.liga)) return 1;
                
                if (a.horario < b.horario) return -1;
                if (a.horario > b.horario) return 1;

                return 0;
            });
        },
        showAlert(title, message) {
            this.$dialogs.alert(message, {
                title, 
                size: 'sm'
            });
        },
        selectLeaguePrint(event) {
            if (event.target.checked) {
                this.selectedPrintList.push(event.target.value);
            } else if(this.selectedPrintList.includes(event.target.value)) {
                this.selectedPrintList.splice(this.selectedPrintList.indexOf(event.target.value), 1);
            }
        },
        changeDate(event) {
            this.date = event.target.value;
        },
        formatHour(date) {
            return moment(date).tz('America/Recife').format('HH:mm');
        },
        formatDateDay(date) {
            return moment(date).locale('pt-br').tz('America/Recife').format('dddd: D MMMM YYYY');
        },
    },
    watch: {
        events(values) {
            this.total = values.length;
        },
        date(value) {
            this.getEvents();
            this.current_day = this.buttons.find(item => item.date == value).label;
        }
    },
    created() {
        this.loadConfigs();
    }
}
</script>

<style scoped>
.main {
    width: 100%;
    margin-top: 56px;
    height: calc(100% - 56px);
}
.main .h-100vh {
    height: calc(100vh - 101px);
}
.home-main .main .container-fluid .row.pt-3 .btn-group .btn,
.home-main .main .container-fluid .row.pt-3 .col .dropdown .dropdown-toggle,
.home-main .main .container-fluid .row.pt-3 .col .form-control.form-sm.form-bg {
    height: 38px;
    position: relative;
}
.home-main .main .container-fluid .row.pt-3 .btn-group .btn .badge,
.home-main .main .container-fluid .row.pt-3 .col .dropdown .dropdown-toggle .badge {
    top: 11px;
    margin-left: 5px;
    position: absolute;
    color: var(--theme-text);
    background-color: var(--theme);
}
.home-main .main .container-fluid .row.pt-3 .btn-group .btn.active .badge {
    color: var(--sport-menu-link)!important;
    background-color: var(--foreground)!important;
}
.home-main .main .container-fluid .row .col .form-control.form-sm.form-bg {
    color: var(--sport-menu-link);
    outline: none;
    border-color: var(--foreground);
    background-color: var(--foreground);
}
.home-main .main .container-fluid .row .col .form-control.form-bg:focus {
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
}
.home-main .main .container-fluid .row .col .input-group-text {
    border-color: var(--foreground);
    background-color: var(--foreground);
}
.home-main .main .container-fluid .row .col .dropdown .dropdown-toggle,
.home-main .main .container-fluid .row .col .dropdown .dropdown-item.active, 
.home-main .main .container-fluid .row .col .dropdown .dropdown-item:active,
.home-main .main .container-fluid .row .col .dropdown .dropdown-item:focus, 
.home-main .main .container-fluid .row .col .dropdown .dropdown-item:hover {
    color: var(--sport-menu-link);
    outline: none!important;
    box-shadow: none!important;
    border-color: var(--foreground);
    background-color: var(--foreground);
}
.home-main .main .container-fluid .row .col .dropdown .dropdown-menu {
    width: 100%;
    background-color: var(--bg-navbar);
}
.home-main .main .container-fluid .row.row-bet-item {
    color: var(--sport-menu-link);
    background-color: var(--gray-dark);
}
.home-main .main .container-fluid .row.row-bet-item:not(:first-child) {
    border-top: 1px solid var(--background);
}
.home-main .main .container-fluid .row.row-bet-item .col {
    padding: 0.5rem!important;
}
.home-main .main .container-fluid .row.row-bet-item .col .text-truncate {
    max-width: 80%;
}
.home-main .main .container-fluid .row.row-bet-item .col .text-style {
    color: var(--theme);
}
.home-main .main .container-fluid .collection-cluster {
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: .25rem;
    height: calc(100vh - 145px);
}
.home-main .main .container-fluid .collection-cluster::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: none;
  border-radius: 0;
}
.home-main .main .container-fluid .collection-cluster::-webkit-scrollbar-thumb {
  background: none;
  border-radius: 0;
}
.home-main .main .container-fluid .collection-cluster .list-group .list-group-item-dark {
    color: var(--theme);
    background-color: var(--foreground);
}
.home-main .main .container-fluid .collection-cluster .list-group .list-group-item-dark .form-check-input {
    margin-top: .5rem;
}
.row.scroll-horizontal {
    overflow-x: auto;
    white-space: nowrap!important;
    flex-wrap: nowrap!important;
}
.row.scroll-horizontal.overflow-initial {
    overflow: initial;
}
.row.scroll-horizontal .col {
    flex: 0 0 auto!important;
}
</style>