<template>
    <div class="home-main">
        <WebNavbar />
        <main class="main">
            <div class="container-fluid" :class="{'h-100': loading || message}">
                <template v-if="loading">
                    <div class="d-flex justify-content-center align-items-center h-100">
                        <div v-html="lds_ring"></div>
                    </div>
                </template>
                <template v-else>
                    <template v-if="message">
                        <div class="d-flex flex-column justify-content-center align-items-center text-center h-100">
                            <div class="display-5">
                                <i class="fas fa-exclamation-circle"></i>
                            </div>
                            <small>{{ message }}</small>
                        </div>
                    </template>
                    <template v-else>
                        <div class="image-wrapper">
                            <VueSlickCarousel v-bind='{"dots": true, "arrows": true, "speed": 500, "focusOnSelect": true, "infinite": true, "autoplay": true, "centerMode": false, "lazyLoad": "ondemand"}'>
                                <div v-for="(item, index) in storeImages" :key="index">
                                    <img class="img-responsive rounded" :src="item.image" border="0">
                                </div>
                            </VueSlickCarousel>
                        </div><!-- /image-wrapper -->
                        <div class="title-wrapper" :class="{'mobile': platform == 'mobile'}">
                            <h2>
                                <div class="d-flex align-items-center justify-content-center">
                                    <span>Produtos disponíveis</span>
                                    <span class="ml-3 text-theme">{{ totalProducts }}</span>
                                </div>
                            </h2>
                        </div><!-- /title-wrapper -->
                        <div class="container" :class="{'p-0': platform == 'mobile'}">
                            <div class="row justify-content-center">
                                <div class="col-lg-4 col-md-6 col-sm-6 mb-3" v-for="(item, index) in products" :key="index">
                                    <div class="card">
                                        <div class="card-header mb-3">
                                            <div class="d-flex flex-row align-items-center justify-content-between">
                                                <div class="text-style font-bold">Pontos</div>
                                                <div class="text-style font-bold">{{ formatValue(item.preco) }}</div>
                                            </div>
                                        </div>
                                        <img class="card-img-top img-fluid" :src="`https://demo.betsnow.net/${item.imagem}`" alt="Card image cap">
                                        <div class="card-body">
                                            <h5 class="card-title text-style font-bold">{{ item.nome }}</h5>
                                            <hr />
                                            <button class="btn btn-block" role="button" @click="redeemProduct(item)">
                                                <i class="fas fa-cart-plus mr-2"></i>Resgatar
                                            </button>
                                        </div>
                                    </div>
                                </div><!-- /col -->
                            </div><!-- /row -->
                        </div><!-- /container -->
                    </template>
                </template>
            </div><!-- /container-fluid -->
        </main>
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></loading>
    </div>
</template>

<script>
import Vue from 'vue'
import {api} from '../../api'
import WebNavbar from './WebNavbar'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import 'v-slim-dialog/dist/v-slim-dialog.css'
import SlimDialog from 'v-slim-dialog'

Vue.use(SlimDialog)

export default {
    data() {
        return {
            message: '',
            products: [],
            totalProducts: 0,
            storeImages: [],
            loading: false,
            isLoading: false,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop'
        }
    },
    components: {
        Loading,
        WebNavbar,
        VueSlickCarousel
    },
    methods: {
        getProducts() {
            
            const self = this;

            self.message = '';
            self.loading = true;

            api.get('store').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.loja = response.data.loja;
                        self.cassino = response.data.cassino;
                        self.cadastro = response.data.cadastro;
                        self.products = response.data.products;
                        self.storeImages = response.data.storeImages;
                    break;
                    default:
                        self.loja = response.data.loja;
                        self.cassino = response.data.cassino;
                        self.message = response.data.message;
                        self.cadastro = response.data.cadastro;
                    break;
                }
            }).catch((error) => {
                try {
                    self.message = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.message = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        redeemProduct(item) {
            
            const self = this;

            self.$dialogs.prompt(`Pontos: <b>${self.formatValue(item.preco)}</b><br/>Produto: <b class="text-uppercase">${item.nome}</b><hr/><p class="mb-3">Informe seu número de celular, para concluir o resgate.</p>`, {
                title: 'Resgatar produto',
                cancelLabel: 'Cancelar',
                okLabel: 'Resgatar',
                size: 'sm'
            }).then(res => {

                if (res.ok) {

                    self.isLoading = true;

                    api.put('store', {
                        id: item._id,
                        cel: self.formatNumber(res.value)
                    }).then((response) => {
                        switch(response.data.result) {
                            case 'success':
                                self.$dialogs.alert(`O produto <b>${item.nome}<b> já é seu. Agora só é aguardar a nossa equipe entrar em contato com você no número de celular que você realizou o cadastro!`, {
                                    title: 'Parabéns!',
                                    size: 'sm'
                                });
                            break;
                            default:
                                self.$dialogs.alert(response.data.message, {
                                    title: 'Atenção',
                                    size: 'sm'
                                });
                            break;
                        }
                    }).catch((error) => {
                        try {
                            self.$dialogs.alert(self.network_erros[error.status]['message'], {
                                title: 'Falha',
                                size: 'sm'
                            });
                        } catch(e) {
                            self.$dialogs.alert(self.network_erros[408]['message'], {
                                title: 'Falha',
                                size: 'sm'
                            });
                        }
                    }).finally(() => {
                        self.isLoading = false;
                    });
                }
            });

            setTimeout(() => self.dialogInput(), 100);
        },
        formatNumber(number) {
            return parseInt(number.toString().replace(/[^0-9]/g, ''));
        },
        dialogInput() {

            const el = document.querySelector('.v-dialog-input');
            const celCashback = window.localStorage.getItem('cashbackCel');

            if (el) {

                if (celCashback) {
                    let custom_event = new Event('input');
                    el.value = this.phoneMask(celCashback);
                    el.dispatchEvent(custom_event);
                }

                el.addEventListener('input', (e) => {
                    e.target.value = this.phoneMask(e.target.value);
                });
            }
        },
        formatValue(nStr) {
            nStr += '';
            let x = nStr.split('.');
            let x1 = x[0];
            let x2 = x.length > 1 ? '.' + x[1] : '';
            let rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + '.' + '$2');
            }
            return x1 + x2;
        },
    },
    watch: {
        products(values) {
            this.totalProducts = values.length;
        }
    },
    created() {
        this.getProducts();
    }
}
</script>

<style scoped>
.main {
    width: 100%;
    margin-top: 56px;
    overflow-y: auto;
    height: calc(100% - 56px);
}
.home-main {
    height: 100%!important;
}
.home-main .main .container-fluid .image-wrapper {
    width: 100%;
    height: auto;
    margin-top: 15px;
}
.home-main .main .container-fluid .title-wrapper {
    width: 100%;
    padding: 1em 0;
    text-align: center;
    position: sticky;
    z-index: 1;
    top: 0;
    background-color: var(--background);
}
.home-main .main .container-fluid .title-wrapper h2 {
    margin-bottom: 0;
    color: var(--sport-menu-link);
    text-transform: uppercase;
    letter-spacing: .1666666667em;
}
.home-main .main .container-fluid .title-wrapper.mobile h2 {
    font-size: 1rem;
}
.img-responsive {
    width: 100%;
    height: auto;
}
.btn {
    color: var(--theme-text);
    background-color: var(--theme);
}
</style>